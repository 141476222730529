<div class="content-dash">
    <div class="title-top">
        <h1>Mis Direcciones</h1>
        <button (click)="toggleAddCardForm()">
            <img src="/assets/icons/plus.svg" alt="">Nueva Dirección
        </button>
    </div>
    
    <!-- Mostrar solo las direcciones en la página actual -->
    <app-address 
        *ngFor="let add of addresses.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)" 
        [direction]="add" 
        (manageStatus)="changeStatus($event)" 
        (editDirection)="update($event)">
    </app-address>

    <!-- Controles de paginación -->
    <div class="pagination">
        <button (click)="previousPage()" [disabled]="currentPage === 1">Anterior</button>
        <span>Página {{currentPage}} de {{totalPages}}</span>
        <button (click)="nextPage()" [disabled]="currentPage === totalPages">Siguiente</button>
    </div>
</div>
