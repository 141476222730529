<button class="close" mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>

   
<div class="form_dialog">
    <h1>Actualizar Dirección</h1>

    <form  [formGroup]="form">
        <div class="form-group">
            <label for="provincia">Provincia</label>
            <select  (change)="onProvinceChange()"  id="provincia"[formControl]="form.controls['province']" required>
                <option value="" disabled>Seleccione una Provincia</option>
                <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>
            </select>
            <small
            *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched" 
            class="form-error-msg"> Provincia es requerido </small>

          </div>
          <div class="form-group">
            <label for="provincia">Corregimiento</label>
            <select id="provincia" [formControl]="form.controls['corregimiento']" required>
                <option value="" disabled>Seleccione un Corregimiento</option>
                <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>
            </select>
            <small
                        *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched" 
                        class="form-error-msg"> Corregimiento es requerido </small>

            
          </div>
      <div class="fullwidth">
        <input type="text" id="direcciones" [formControl]="form.controls['adress']"   placeholder="Añadir direccion" required>
        <small
                *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched" 
                class="form-error-msg"> Dirección es requerido </small>
      </div>
    
      <button (click)="update()" >Actualizar</button>
    </form>
  </div>
  