import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  public logoImage = new EventEmitter<String>();

  constructor() { 

  }

  setPrincipalBannerImage(url : string){
    localStorage.setItem("principalBanner" , url );
  }

  getPrincipalBannerImage(){
    return localStorage.getItem("principalBanner");
  }

  setLogoImage(url : string){
    localStorage.setItem("logoImage" , url );
  }

  getLogoImage(){
    return localStorage.getItem("logoImage");
  }

  getImagePalete(){


    let image;
    let palete = localStorage.getItem("pal");

    switch(palete){
      case "pal1" : image = "../../../assets/img/header-bg.jpg";
      break;
      case "pal2" : image = "../../../assets/img/header-bg2.jpg";
      break;
      default : image = "../../../assets/img/header-bg3.jpg";
    }
    
    return "url(" + image + ")";
  }

}
