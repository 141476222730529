<button class="close" mat-icon-button (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</button>
<div class="form_dialog">
  <h1>Actualizar Suscripción</h1>

  <form [formGroup]="form">
    <div class="two-column">
      <div class="form-group">
        <label for="product">Producto</label>
        <input readonly [value]="form.controls['product'].value.name_plan" type="text" id="product" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="price">Precio</label>
        <input readonly [formControl]="form.controls['price']" type="text" id="price" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="frecuency">Frecuencia de cobro</label>
        <input readonly [formControl]="form.controls['frecuency']" type="text" id="frecuency" placeholder="" required>
      </div>
      <div class="form-group" *ngIf="data.suscription.status !== 3 && data.suscription.status !== 2">
        <label for="next">Próximo Cobro</label>
        <input readonly [formControl]="form.controls['next']" type="text" id="next" placeholder="" required>
      </div>
    </div>
    <hr>
    <div style="text-align: end;">
      <a class="text-dark" type="button" style="cursor: pointer;" (click)="addressesPopUp({})">Cambiar dirección</a>
    </div>
    <div class="three-column">
      <div class="form-group">
        <label for="province">Provincia</label>
        <input readonly [formControl]="form.controls['province']" type="text" id="province" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="corregimiento">Corregimiento</label>
        <input readonly [formControl]="form.controls['corregimiento']" type="text" id="corregimiento" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="adress">Dirección</label>
        <input readonly [formControl]="form.controls['adress']" type="text" id="adress" placeholder="" required>
      </div>
    </div>
    <br>
    <div style="text-align: end;">
      <a class="text-dark" type="button" style="cursor: pointer;" (click)="tdcPopUp({})">Cambiar tarjeta</a>
    </div>
    <div class="two-column">
      <div class="form-group">
        <label for="name_tdc">Titular</label>
        <input readonly [formControl]="form.controls['name_tdc']" type="text" id="name_tdc" placeholder="" required>
      </div>
      <div class="form-group">
        <label for="number_tdc">Tarjeta</label>
        <input readonly [formControl]="form.controls['number_tdc']" type="text" id="number_tdc" placeholder="" required>
      </div>
    </div>
  </form>

  <div *ngIf="data.suscription.status == 3" style="text-align: end;">
    <a class="text-dark" type="button" style="cursor: pointer;" (click)="validateSubscription('Activar', 1)">Activar suscripción</a>
  </div>

  <div *ngIf="data.suscription.status == 1 && canPause" style="text-align: end;">
    <a style="display: block;" class="text-dark" type="button" style="cursor: pointer;" (click)="validateSubscription('Pausar', 2)">Pausar suscripción</a>
  </div>

  <div class="botones">
    <button (click)="update()">Actualizar</button>
    <div *ngIf="data.suscription.status != 2 && canCancel" style="text-align: end;">
      <a class="text-dark" type="button" style="cursor: pointer;" (click)="validateSubscription('Cancelar', 3)">Cancelar suscripción</a>
    </div>
  </div>
</div>
