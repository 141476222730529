import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent {

  form: any ;

  constructor(private web : WebService, private notification : NotificationService, private loader : LoaderService) {
  
    this.form = new FormGroup({
      actual_password: new FormControl('', [Validators.required]),
      new_password: new FormControl('', [Validators.required,Validators.minLength(6)]),
      newPasswordConfirm:  new FormControl('', [Validators.required,Validators.minLength(6)])
    });
   }

   send(){
    this.form.value.user = JSON.parse(localStorage.getItem('sesion')!).user_id;
    this.form.value.partner = +localStorage.getItem('partner');

    if(!this.form.valid){
      return this.notification.showError("Por favor complete el formulario correctamente...");
    }

    if(this.form.value.new_password !== this.form.value.newPasswordConfirm){
      return this.notification.showError("Contraseñas no coinciden")
    }
    this.loader.open();

    this.web.put(this.form.value, this.web.HOST + "/onboarding/changePassword").subscribe( response =>{
      this.loader.close();
      this.form.reset();
      this.notification.showSuccess("Contraseña cambiada con exito");
    }, err =>{
      this.loader.close();
      this.notification.showError(err.message);
      //console.log(err)
    })

  }

}