<button class="close" mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>

<div class="login-modal-content" [ngClass]="themeService.currentPalette">
    <div class="modal-login">
        <div class="background-white"> 
            <form *ngIf="!showForgotPassword" [formGroup]="form" (ngSubmit)="signin()" >

                <h1>Por favor, inicia sesión</h1>
                <input [formControl]="form.controls['username']"   type="text" placeholder="Email">
                <small 
                *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched" 
                class="form-error-msg"> Correo es requerido </small>

                <input [formControl]="form.controls['password']"  type="password" placeholder="Password">
                <small 
                *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                class="form-error-msg"> Contraseña es requerido </small>

                <p>¿Olvidaste tu Password?</p>
                <a (click)="toggleForgotPassword()">Haz click aquí</a>
                <!-- <p>¿Olvidaste tu Password?</p>
                <a routerLink="/forgot">Haz click aquí</a> -->
                <div class="options">
                    <!-- <div class="checkbox">
                        <input type="checkbox" id="checkbox-remember" class="cookies">
                        <label for="checkbox-remember">Recuerdame<br>en este sitio</label>
                    </div> -->
                    <button>Entrar</button>
                </div>

            </form>
            <form *ngIf="showForgotPassword" [formGroup]="resetForm" (ngSubmit)="reset()">
                <!-- Aquí tu formulario para restablecer la contraseña -->
                <h1>Restablecer contraseña</h1>
                <p>Te enviaremos un correo con tu nueva contraseña</p>
                <!-- Corrige el formControl para usar resetForm en lugar de form -->
                <input formControlName="email" type="text" placeholder="Ingrese su correo">

                <small *ngIf="resetForm.get('email').hasError('required') && resetForm.get('email').touched" class="form-error-msg"> Correo es requerido </small>
                <div class="options">
                  <button type="submit">Recuperar</button>
                </div>
                <!-- Botón para volver al formulario de login -->
                <p>Recordé mi contraseña</p>
                <a (click)="toggleForgotPassword()">Volver al login</a>
            </form>
           
        </div>
    </div>
</div>