<button class="close" mat-icon-button (click)="closeDialog()">
  <mat-icon>close</mat-icon>
</button>

<div class="form_dialog">
  <h1>Añadir Tarjeta</h1>
  <form [formGroup]="form">
    <div class="form-group">
      <input [formControlName]="'nameHolderCreditCard'" type="text" id="nombreTitular" name="tarjeta" placeholder="Nombre de Tarjeta" required>
      <small *ngIf="form.controls['nameHolderCreditCard'].hasError('required') && form.controls['nameHolderCreditCard'].touched" class="form-error-msg">
        Nombre de tarjeta es requerido
      </small>
    </div>
    <div class="form-group">
      <input (input)="formatCreditCardNumber()" (keypress)="onlyNumberKey($event)" [formControlName]="'numberCreditCard'" type="text" id="numero-tarjeta" name="numeroTarjeta" placeholder="Número de Tarjeta" required>
      <small *ngIf="form.controls['numberCreditCard'].hasError('required') && form.controls['numberCreditCard'].touched" class="form-error-msg">
        Número de tarjeta es requerido.
      </small>
      <small *ngIf="form.controls['numberCreditCard'].hasError('invalidLength') && form.controls['numberCreditCard'].touched" class="form-error-msg">
        Número de tarjeta debe tener entre 13 y 19 dígitos.
      </small>
      <small *ngIf="form.controls['numberCreditCard'].hasError('invalidCardNumber') && form.controls['numberCreditCard'].touched" class="form-error-msg">
        Número de tarjeta inválido.
      </small>
    </div>
    <div class="form-group">
      <select [formControlName]="'expMonthCreditCard'" id="mesExpiracion" name="mes-expiracion" required>
        <option value="" disabled selected>Seleccionar Mes</option>
        <option *ngFor="let mes of meses" [value]="mes.value">{{ mes.desc }}</option>
      </select>
      <small *ngIf="form.controls['expMonthCreditCard'].hasError('required') && form.controls['expMonthCreditCard'].touched" class="form-error-msg">
        Mes es requerido
      </small>
    </div>
    <div class="form-group">
      <select [formControlName]="'expYearCreditCard'" id="añoExpiracion" name="ano-expiracion" required>
        <option value="" disabled selected>Seleccionar Año</option>
        <option *ngFor="let year of years" [value]="year.value">{{ year.desc }}</option>
      </select>
      <small *ngIf="form.controls['expYearCreditCard'].hasError('required') && form.controls['expYearCreditCard'].touched" class="form-error-msg">
        Año es requerido
      </small>
    </div>
    <div class="form-group">
      <input maxlength="4" (keypress)="onlyNumberKey($event)" [formControlName]="'ccv'" type="password" id="cvv" name="cvv" placeholder="CVV" required>
      <small *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched" class="form-error-msg">
        CVV es requerido
      </small>
    </div>
    <div class="form-group">
      <input [formControlName]="'city'" type="text" id="city" name="city" placeholder="Ciudad" required>
      <small *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched" class="form-error-msg">
        Ciudad  es requerido
      </small>
    </div>
    <div class="form-group">
      <input [formControlName]="'street'" type="text" id="street" name="street" placeholder="Calle" required>
      <small *ngIf="form.controls['street'].hasError('required') && form.controls['street'].touched" class="form-error-msg">
        Calle  es requerido
      </small>
    </div>
    <div class="form-group">
      <input [formControlName]="'postalCode'" type="text" id="code" name="tarjeta" placeholder="Código postal" required (keypress)="onlyNumberKey($event)">
      <small *ngIf="form.controls['postalCode'].hasError('required') && form.controls['postalCode'].touched" class="form-error-msg">
        Código postal es requerido.
      </small>
      <small *ngIf="form.controls['postalCode'].hasError('pattern') && form.controls['postalCode'].touched" class="form-error-msg">
        Código postal solo puede contener números.
      </small>
    </div>
    <div class="form-group">
      <input [formControlName]="'phone'" type="text" id="phone" name="phone" placeholder="Teléfono" required (keypress)="onlyNumberKey($event)">
      <small *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched" class="form-error-msg">
        Teléfono es requerido.
      </small>
      <small *ngIf="form.controls['phone'].hasError('pattern') && form.controls['phone'].touched" class="form-error-msg">
        Teléfono solo puede contener números.
      </small>
    </div>
   
  </form>
  <button class="create" (click)="create()">Crear</button>
</div>
