<div class="main"  [ngClass]="themeService.currentPalette">
    
    
    <div class="login-modal-content">
        <div class="modal-login">
            <div class="background-white">
                <form  [formGroup]="form" (ngSubmit)="signin()" >

                    <h1>Sesión</h1>
                    <input [formControl]="form.controls['username']"   type="text" placeholder="Email">
                    <small 
                    *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched" 
                    class="form-error-msg"> Correo es requerido </small>

                    <input [formControl]="form.controls['password']"  type="password" placeholder="Password">
                    <small 
                    *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                    class="form-error-msg"> Contraseña es requerido </small>

                    <p>¿Olvidaste tu Password?</p>
                    <a (click)="onforgotClick()">Haz click aquí</a>
                    <div class="options">
                        <!-- <div class="checkbox">
                            <input type="checkbox" id="checkbox-remember" class="cookies">
                            <label for="checkbox-remember">Recuerdame<br>en este sitio</label>
                        </div> -->
                        <button>Entrar</button>
                    </div>

                </form>
               
            </div>
            <div class="transparent-background">
                <p>¿Nueva cuenta?</p>
                <button (click)="onRegisterClick()" >Regístrate</button>
            </div>
        </div>
    </div>
    <img src="../../assets/images/grafica2.png" alt="graff">
</div>