import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-mod-address',
  templateUrl: './mod-address.component.html',
  styleUrls: ['./mod-address.component.scss']
})
export class ModAddressComponent {
  constructor(public dialogRef: MatDialogRef<ModAddressComponent>){}
  closeDialog(): void {
    this.dialogRef.close();
  }
}
