<div class="main" [ngClass]="themeService.currentPalette">
    <div class="login-modal-content">
      <div class="modal-login">

        <form  [formGroup]="form" (ngSubmit)="reset()" >

          <div class="background-white">
            <h1>Recuperar contraseña</h1>
           
            <input  [formControl]="form.controls['email']" type="text" placeholder="Ingrese su correo">
            <small 
            *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
            class="form-error-msg"> Correo es requerido </small>
            
            <p>Te enviaremos un correo con tu nueva contraseña</p>
            <div class="options">
              <button>Recuperar</button>
            </div>
          </div>
          <div class="transparent-background">
            <p>Ya tengo mi clave</p>
            <a (click)="goToLogin()">Iniciar sesión</a>
          </div>

        </form>
      </div>
    </div>
    <img src="../../assets/images/grafica2.png" alt="graff">
  </div>
  