
<div class="column" [ngClass]="themeService.currentPalette">
    <div class="logo-bank">
        <img src="/assets/images/map.png" alt="">
    </div>
    <div class="credit_data">
        <h4 class="tx_tipo">{{ direction.address }}</h4>
        <p>{{ direction.provinceName }}, {{ direction.corregimientoName }}</p>
        <p>{{ direction.phone == null ? "N/A" : direction.phone }}</p>
        <span class="verified">{{ direction.status == 1 ? "Activa" : "Inactiva" }}</span>
        
    </div>
<div class="button-action">
    <div *ngIf="direction.status == 1" ><button (click)="changeStatus(2)" class="outline w-100 btn btn-primary btn-lg">Desactivar</button></div>
    <div *ngIf="direction.status == 2" ><button (click)="changeStatus(1)" class="outline w-100 btn btn-primary btn-lg">Activar</button></div>
    <div ><button  (click)="sendToEdit(direction)" class="w-100 btn btn-primary btn-lg" >Actualizar</button></div>
</div>
</div>
