import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  
  isFromCheckout(email : any){
    localStorage.setItem("isFromCheckout" , email);
  }

  setPasswordFromCheckout(pass : any){
    localStorage.setItem("passFromCheckout" , pass);
  }

  getPassFromCheckout(){
    return localStorage.getItem("passFromCheckout");
  }

  getFromCheckout(){
    return localStorage.getItem("isFromCheckout");
  }

  removeFromCheckout(){
    localStorage.removeItem("isFromCheckout");
    localStorage.removeItem("passFromCheckout");
  }
  
  constructor() { }
}
