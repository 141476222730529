<app-header></app-header>
<div class="checkout-container" [ngClass]="themeService.currentPalette">
    <div  *ngIf="isActive"   class="title-checkout"><h1>checkout</h1></div>
    <div  *ngIf="!isActive"   class="title-checkout"><h1>PORTAL EN MANTENIMIENTO</h1></div>
    <div class="checkout-detail">
    <div class="checkout-column left-column">
      <!-- Bloque de inicio de sesión y registro -->
      <!-- <div *ngIf="!auth.isLoggedIn()" class="login-register-block">
        <h2>¿Tiene cuenta?</h2>
        <div class="button-login">
        <button (click)="showLoginPopup()" class="btn-login">Iniciar Sesión</button>
        <button class="btn-register">Registrarse</button>
        </div>
      </div> -->
      
      <!-- Formulario de checkout -->
      <div *ngIf="isActive" class="checkout-form-block"><div class="checkout-dialog">
        <h2>Información de Envío y Pago</h2><button *ngIf="auth.isLoggedIn()"  (click)="togglemisdirecciones()"><img src="/assets/icons/plus.svg" alt="">Mis Direcciones</button>
        </div><form [formGroup]="form" >
            <!-- Columna izquierda del formulario -->
            <div class="form-column">
              <div class="form-group">
                <label for="nombre">Nombre:</label>
                <input [formControl]="form.controls['name']"  type="text" id="nombre" name="nombre" placeholder="Nombre" required>
                <small
                        *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                        class="form-error-msg"> Nombre es requerido </small>
              </div>
              <div class="form-group">
                <label for="apellido">Apellido:</label>
                <input  [formControl]="form.controls['lastname']" type="text" id="apellido" name="apellido" placeholder="Apellido" required>
                <small
                    *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched"
                    class="form-error-msg"> Apellido es requerido </small>
              </div>
              <div class="form-group">
                <label for="direccion">Dirección:</label>
                <input [formControl]="form.controls['address']" type="text" id="direccion" name="direccion" placeholder="Dirección" required>
                <small
                    *ngIf="form.controls['address'].hasError('required') && form.controls['address'].touched"
                    class="form-error-msg"> Dirección es requerido </small>
              </div>
           
              <div *ngIf="!auth.isLoggedIn()"  class="form-group">
                <label for="email">Email:</label>
                <input  [formControl]="form.controls['email']"  type="email" id="email" name="email" placeholder="Email" required>
                <small
                    *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                    class="form-error-msg"> Email es requerido </small>
              </div>
              <div class="form-group">
                <label for="provincia">Provincia:</label>
                <select  (change)="onProvinceChange()" [formControl]="form.controls['province']" id="provincia" name="provincia">
                  <option disabled="" selected="" value="">Elije una opción</option>
                  <option *ngFor="let p of provinces" [value]="p.id" >{{p.name}}</option>

              </select>
              <small
              *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched"
              class="form-error-msg"> Provincia es requerido </small>

              </div>
              <div class="form-group">
                <label for="corregimiento">Corregimiento:</label>
                <select [formControl]="form.controls['corregimiento']" id="corregimiento" name="corregimiento">
                  <option disabled="" selected="" value="">Elije una opción</option>
                  <option *ngFor="let c of corregimientos" [value]="c.id" >{{c.name}}</option>

                </select>

                <small
                *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched"
                class="form-error-msg"> Corregimiento es requerido </small>

              </div>
            </div>
<hr>
            <div class="checkout-dialog">
            <h2  class="pago">Información de pago</h2> <button *ngIf="auth.isLoggedIn()" (click)="togglemistarjetas()"><img src="/assets/icons/plus.svg" alt="">Mis Tarjetas</button>
          </div>
            <div class="form-group">
                <label for="tarjeta">Nombre de Tarjeta:</label>
                <input [formControl]="form.controls['name_tdc']" type="text" id="tarjeta" name="cc-name" placeholder="Nombre de Tarjeta" required>
                <small
                    *ngIf="form.controls['name_tdc'].hasError('required') && form.controls['name_tdc'].touched"
                    class="form-error-msg"> Nombre de tarjeta es requerido </small>
              </div>
              <div class="form-group">
                <label for="numero-tarjeta">Número de Tarjeta:</label>
                <input
                  (input)="onCreditCardInput(); formatCreditCardNumber()"
                  (keypress)="onlyNumberKey($event)"
                  [formControl]="form.controls['number_tdc']"
                  type="text"
                  id="numero-tarjeta"
                  name="cc-number"
                  placeholder="Número de Tarjeta"
                  required
                />
                <small
                  *ngIf="form.controls['number_tdc'].hasError('required') && form.controls['number_tdc'].touched"
                  class="form-error-msg"
                >
                  Número es requerido
                </small>
                <small
                  *ngIf="form.controls['number_tdc'].hasError('invalidLength') && form.controls['number_tdc'].touched"
                  class="form-error-msg"
                >
                  Número de tarjeta debe tener exactamente 16 dígitos.
                </small>
                <small
                  *ngIf="form.controls['number_tdc'].hasError('invalidCardNumber') && form.controls['number_tdc'].touched"
                  class="form-error-msg"
                >
                  Número de tarjeta no es válido.
                </small>
              </div>


              <div class="form-column">

                <div class="form-group">
                  <label for="city">Ciudad:</label>
                  <input [formControl]="form.controls['city']"  type="text" id="city" name="city" placeholder="Ciudad" required>
                  <small
                          *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                          class="form-error-msg"> Ciudad es requerido </small>
                </div>
                <div class="form-group">
                  <label for="street">Calle:</label>
                  <input  [formControl]="form.controls['street']" type="text" id="street" name="street" placeholder="Calle" required>
                  <small
                      *ngIf="form.controls['street'].hasError('required') && form.controls['street'].touched"
                      class="form-error-msg"> Calle es requerido </small>
                </div>

                <div class="form-group">
                  <label for="postalCode">Código Postal:</label>
                  <input (keypress)="onlyNumberKey($event)"  [formControl]="form.controls['postalCode']" type="text" id="postalCode" name="postalCode" placeholder="Código" required>
                      <small *ngIf="form.controls['postalCode'].hasError('required') && form.controls['postalCode'].touched" class="form-error-msg"> **Requerido </small>
                      <small *ngIf="form.controls['postalCode'].hasError('pattern') && form.controls['postalCode'].touched" class="form-error-msg"> **Formato inválido </small>
                </div>

                <div class="form-group">
                  <label for="phone">Teléfono:</label>
                  <input (keypress)="onlyNumberKey($event)"  [formControl]="form.controls['phone']" type="text" id="phone" name="phone" placeholder="Teléfono" required>
                  <small *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched" class="form-error-msg"> **Requerido </small>
                  <small *ngIf="form.controls['phone'].hasError('pattern') && form.controls['phone'].touched" class="form-error-msg"> **Formato inválido </small>
                </div>



              </div>
              
              
              <div class="datos-mac">
              <div class="form-group">
                <label for="mes-expiracion">Mes de Expiración:</label>
                <select [formControl]="form.controls['month']"  id="mes-expiracion" name="mes-expiracion" required>
                  <option value="" disabled selected>Seleccionar Mes </option>
                  <option value="01" id="mes-exp-0">Enero</option>
                  <option value="02" id="mes-exp-1">Febrero</option>
                  <option value="03" id="mes-exp-2">Marzo</option>
                  <option value="04" id="mes-exp-3">Abril</option>
                  <option value="05" id="mes-exp-4">Mayo</option>
                  <option value="06" id="mes-exp-5">Junio</option>
                  <option value="07" id="mes-exp-6">Julio</option>
                  <option value="08" id="mes-exp-7">Agosto</option>
                  <option value="09" id="mes-exp-8">Septiembre</option>
                  <option value="10" id="mes-exp-9">Octubre</option>
                  <option value="11" id="mes-exp-10">Noviembre</option>
                  <option value="12" id="mes-exp-11">Diciembre</option>
                </select>
                <small
                *ngIf="form.controls['month'].hasError('required') && form.controls['month'].touched"
                class="form-error-msg"> Mes es requerido </small>

              </div>
              <div class="form-group">
                <label for="ano-expiracion">Año de Expiración:</label>
                <select [formControl]="form.controls['year']" id="ano-expiracion" name="ano-expiracion" required>
                  <option value="" disabled selected>Seleccionar Año</option>
                  <option *ngFor="let year of expirationYears" [value]="year.value" [id]="'ano-exp-' + year.value">
                    {{ year.label }}
                  </option>
                </select>
                <small
                  *ngIf="form.controls['year'].hasError('required') && form.controls['year'].touched"
                  class="form-error-msg">
                  campo requerido
                </small>
              </div>
              <div class="form-group">
                <label for="cvv">CVV:</label>
                <input maxlength="4"  (keypress)="onlyNumberKey($event)" (input)="onCVVInputChange()" [formControl]="form.controls['ccv']" type="password" id="cvv" name="cvv" placeholder="CVV" required>
                <small
                *ngIf="form.controls['ccv'].hasError('required') && form.controls['ccv'].touched"
                class="form-error-msg"> CVV es requerido </small>
              </div> </div>
            <button (click)="checkout()" class="btn-submit">Realizar Pago</button>
          
          </form>
      </div>
    </div>
  
  
<div *ngIf="isActive" class="checkout-summary">
    <h2>Resumen del Pedido</h2>
    <div class="product-item">
      <img src="{{plan?.url_image}}" alt="Producto 1">
      <div class="product-info">
        <h3>{{ plan?.name_plan }}</h3>
        <p>Descripcion: {{ plan?.description }} </p>
      </div>
    </div>
    <hr>
    <!-- Total -->
    <div class="order-total">
      <p>Total: ${{ plan?.feeCost_plan }}</p>
    </div>
  </div>
  
</div>
  </div>
  