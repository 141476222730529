<div class="main" [ngClass]="themeService.currentPalette">
    <div class="login-modal-content">
      <div class="modal-login">
        <div class="background-white">

          <form [formGroup]="form" (ngSubmit)="signup()" >
            <h1>Registro</h1>
            <input [formControl]="form.controls['name_customer']"  type="text" placeholder="Nombre">
            <small
                                            *ngIf="form.controls['name_customer'].hasError('required') && form.controls['name_customer'].touched"
                                            class="form-error-msg"> Nombre es requerido </small>

            <input [formControl]="form.controls['lastName_customer']"  type="text" placeholder="Apellido">
            <small
                                            *ngIf="form.controls['lastName_customer'].hasError('required') && form.controls['lastName_customer'].touched"
                                            class="form-error-msg"> Apellido es requerido </small>
            <input [formControl]="form.controls['identifier_customer']"  type="text" placeholder="Cédula">
            <small
                                            *ngIf="form.controls['identifier_customer'].hasError('required') && form.controls['identifier_customer'].touched"
                                            class="form-error-msg"> Cédula es requerido </small>
            <input [formControl]="form.controls['email_customer']" type="text" placeholder="Email">
            <small
                                            *ngIf="form.controls['email_customer'].hasError('required') && form.controls['email_customer'].touched"
                                            class="form-error-msg"> Email es requerido </small>

            <input [formControl]="form.controls['phoneNumber_customer']"  type="text" placeholder="Teléfono">
            <div *ngIf="showButtons" class="options">
              <button>Registrarse</button>
            </div>
          </form>

         
        </div>
        <div class="transparent-background">
          <p>¿Ya tienes una cuenta?</p>
          <a (click)="goToLogin()">Iniciar sesión</a>
        </div>
      </div>
    </div>
    <img src="../../assets/images/grafica2.png" alt="graff">
  </div>
  