import { trigger, state, style, transition, animate } from '@angular/animations';

export const sidebarAnimation = trigger('openClose', [
  state(
    'open',
    style({
      width: '250px', // Ancho del sidebar cuando está abierto
    })
  ),
  state(
    'closed',
    style({
      width: '0', // Ancho del sidebar cuando está cerrado
    })
  ),
  transition('open <=> closed', [animate('0.3s ease-in-out')]),
]);
