export class Plan {

    private name : String;

    private price : Number;

    private description : String ; 

    private image : String ;

    private service : Number;

    private id : Number;

    private is_visible_plan: boolean;

    constructor(name : String , price : Number, description : String, image : String, id : Number, service : Number,  is_visible_plan: boolean){
        this.name = name;
        this.price = price;
        this.description = description;
        this.image = image;
        this.id = id;
        this.service = service;
        this.is_visible_plan = is_visible_plan;
    }

    getName(): String{
        return this.name;
    }

    getPrice() : Number {
        return this.price;
    }

    getDescription() : String {
        return this.description;
    }

    getImage() : String {
        return this.image;
    }

    getID() : Number {
        return this.id;
    }

    getService() : Number {
        return this.service;
    }
    isVisiblePlan(): boolean {
        return this.is_visible_plan;
      }
}