import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { ThemeService } from '../theme.service';
import { ImagesService } from '../services/image/images.service';
import { AuthService } from '../services/auth/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isMobile = false;
  isMenuOpen = false;
  logoUrl : string;
  isPreview : Boolean = false;
  param : any;
  @Input() navigation = true;

  constructor(private router: Router,private activateRoute : ActivatedRoute,public themeService: ThemeService,private breakpointObserver: BreakpointObserver, private imageService : ImagesService, public authService : AuthService) {
    this.activateRoute.params.subscribe( params =>{
       // ESTO ES PARA VALIDAR SI ES UNA VISTA PREVIA O SE ESTA CARGANDO UN PORTAL YA CREADO
       this.isPreview = params["id"] != undefined ? true : false;
       if(this.isPreview){
        this.param = params["id"]
       }
    });
  }
  getImageLogo() {
    const imageName = 'logo.png';
    const imageUrl = `../assets/images/${imageName}`;
    return imageUrl;
  }

  getLogoUrl(){
    return this.imageService.getLogoImage();
  }
  
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait, // Puedes ajustar las condiciones según tus necesidades
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  goLogin(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/login"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/login"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  goDashboard(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/dashboard-content"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard/dashboard-content"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  goHome(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }
}
