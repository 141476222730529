
<body [ngClass]="themeService.currentPalette">
    <div class="main-content">
        <div  class="background">
            <div>
                <app-header  [navigation]="aloudNavigation"  class="navbar"></app-header>
            </div>
            <div class="main-image">
                <div class="text">
                    <h1 *ngIf="isSuscriptionActive">{{ title }}</h1>
                    <h1 *ngIf="!isSuscriptionActive">PORTAL EN MANTENIMIENTO</h1>
                    <p>{{ description }}</p>
                <!--a href="#planes-section" class="btn btn-primary">Planes</a-->
                </div>
                <div class="image-right">
                    <img src="{{ imagePrincipal }}" alt="imagenes del header">
                </div>
            </div>
        </div>
        <div class="background2" id="planes-section">
            <div class="plans">
                <h1>Precios</h1>
                <p>{{ price_title }}</p>
                <div>
                    <app-plan-contempo [navigation]="aloudNavigation" ></app-plan-contempo>
                </div>
            </div>
        </div>
        <div [ngClass]="{
            'banner-content': selectedPalette === 'Billcentrix',
            'banner-content-modern': selectedPalette === 'Moderna',
            'banner-content-violet': selectedPalette === 'Violeta'
          }" >
            <div class="image">
                <img src="{{ imagePromotional }}" alt="imagen del banner">
            </div>
            <div class="text">
                <h1>{{ banner_title }}</h1>
                <p>{{ banner_description }}</p>
            </div>
        </div>
        <!-- <div [ngClass]="{
            'footer': selectedPalette === 'Billcentrix',
            'footer-modern': selectedPalette === 'Moderna',
            'footer-violet': selectedPalette === 'Violeta'
          }" >
            <h2>Texto en el pie de página</h2>
            <hr>
            <div class="mail">
                <img *ngIf="selectedPalette === 'Billcentrix'" src="../../../assets/icons/mail.png" alt="icono de mail">
                <img *ngIf="selectedPalette === 'Moderna'" src="../../../assets/icons/mail-green.png" alt="icono de mail">
                <img *ngIf="selectedPalette === 'Violeta'" src="../../../assets/icons/mail-fucsia.png" alt="icono de mail">
                <h2>Forma@<strong>decontacto.com</strong></h2>
            </div>
        </div> -->
        <div class="footer"></div>
    </div>

</body>