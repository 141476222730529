<div class="plan-contempo" [ngClass]="themeService.currentPalette">
    <div class="plan-contempo-content">
      <carousel class="carru" [ngClass]="{'single-carousel': visiblePlans.length === 1}" [itemsPerSlide]="isLargeScreen ? 2 : 1">
        <slide *ngFor="let plan of visiblePlans" class="slider" [ngClass]="{'single-plan': visiblePlans.length === 1}">
              <div class="carousel-container">
                <div class="preview">
                  <div class="header">
                    <div class="circle">
                      <img [src]="plan.getImage()" alt="imagen planes">
                    </div>
                  </div>
                  <p>{{ plan.getName() }}</p>
                  <h1 class="big-number">${{ plan.getPrice() }}</h1>
                  <div class="including">
                    <p #descripcionPlan>{{ plan.getDescription() }}</p>
                  </div>
                  <div class="select">
                    <button (click)="onSelectPlan(plan)">Lo quiero</button>
                  </div>
                </div>
              </div>
            </slide>
            <div class="carousel-controls">
              <button class="carousel-nav-left" (click)="prevSlide()"> <mat-icon>arrow_back</mat-icon></button>
              <button class="carousel-nav-right" (click)="nextSlide()"><mat-icon>arrow_forward</mat-icon></button>
            </div>
          </carousel>
    </div>
</div>