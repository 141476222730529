import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { WebService } from 'src/app/services/web.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoaderService } from 'src/app/services/loader.service';

export interface direcciones {
  nombre: string;
  tarjetas: number;
  acciones: string;
}

@Component({
  selector: 'app-misdirecciones',
  templateUrl: './misdirecciones.component.html',
  styleUrls: ['./misdirecciones.component.scss']
})
export class MisdireccionesComponent implements OnInit {

  addresses : any = [];
  empty = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MisdireccionesComponent>,
    private auth : AuthService,
    private web : WebService,
    private notification : NotificationService,
    private loader : LoaderService
  ) {}

  displayedColumns: string[] = ['name' ,'adress','actions'];
  dataSource = new MatTableDataSource<direcciones>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.loader.open();
    this.web.get(this.web.HOST + "/adress/customer/" + this.auth.getCustomer()).subscribe(response => {
      this.loader.close();

      if(response.result.length == 0){
        this.empty = true;
        this.notification.showInfo("No se encontraron direcciones registradas");
        return;
      }

      for(let r of response.result){
        if(r.status == 1){ // SOLO SI LA DIRECCION SE ENCUENTRA ACTIVA
          this.addresses.push(r);
        }
      }

      this.dataSource = new MatTableDataSource<any>(this.addresses);
      this.dataSource.paginator = this.paginator; // Esta línea asegura que el paginador está asignado al dataSource
    }, err => {
      this.notification.showError(err);
      this.loader.close();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  select(row: any) {
    this.dialogRef.close(row);
  }
}
