
<div class="content-dash">
    <div class="title-top">
        <h1>Mis Tarjetas</h1>
        <button (click)="toggleAddCardForm()">
            <img src="/assets/icons/plus.svg" alt="">Añadir Tarjeta
        </button>
    </div>
    
    <!-- Mostrar solo las tarjetas en la página actual -->
    <app-creditcard-info 
        *ngFor="let t of tdc.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)" 
        [tarjeta]="t" 
        (manageStatus)="changeStatus($event)">
    </app-creditcard-info>
    
    <!-- Controles de paginación -->
    <div class="pagination">
        <button (click)="previousPage()" [disabled]="currentPage === 1">Anterior</button>
        <span>Página {{currentPage}} de {{totalPages}}</span>
        <button (click)="nextPage()" [disabled]="currentPage === totalPages">Siguiente</button>
    </div>
</div>
