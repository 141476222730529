import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/loader.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-fac-popup',
  templateUrl: './fac-popup.component.html',
  styleUrls: ['./fac-popup.component.css']
})
export class FacPopupComponent implements OnInit {

  responseForm : any;

  constructor(
    private webService : WebService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FacPopupComponent>,
    private loader : LoaderService
  ) { }

  ngOnInit(): void {
    this.loader.open();
    this.responseForm = this.data.form
    setTimeout(() => {
      this.loader.close();},5000);
  }

  exit() {
    if(this.data.id != null){
      this.webService.get(this.webService.HOST + "/hasplan/" + this.data.id).subscribe(chp =>{
        if(chp.result.status_customerPlan  != 4){
          this.dialogRef.close(chp.status_customerPlan);
        }
      })
    }else{
      //console.log("CONSULTANDO: " + this.webService.HOST + "/creditcard/" + this.data.creditCardId)
      this.webService.get(this.webService.HOST + "/creditcard/" + this.data.creditCardId).subscribe ( creditcard =>{
        //console.log("RESPUESTA: " , creditcard)
        if(creditcard.result.status == 1){
          this.dialogRef.close(creditcard.result.verified);
        }
      }, err =>{
        console.error(err); // LA TARJETA FUE ELIMINADA NO SE ENCONTRO
        this.dialogRef.close(false);
      });
    }
    
  }

}
