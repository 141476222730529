import { Injectable } from '@angular/core';
import {HttpHeaders } from '@angular/common/http';
import { HttpClient} from '@angular/common/http';
import { WebService } from '../web.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpOptions : any;

  APP_USER_TIME = "TIME_USER";
  APP_TIME_SESSION = 10;

  constructor(private webService : WebService, private http: HttpClient) { }

  public createAuthorizationHeader(): HttpHeaders {
    if(localStorage.getItem('user') != null){
        return new HttpHeaders({
            'Authorization': <any> localStorage.getItem('token') ,
            'User' : <any> localStorage.getItem('user') ,
            'Access-Control-Allow-Origin': '*'
          });
    }else{
        return new HttpHeaders({
            'Authorization': <any> localStorage.getItem('token'),
            'Access-Control-Allow-Origin': '*'
          });
    }  
  }

  public createLoginHeader(): HttpHeaders {
      return new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        });
  }

  // PARA VERIFICAR SESION
  public isLoggedIn(): Boolean {
    if(localStorage.getItem("sesion") != null){
      return true
    }
    return false;
  }
  
  // PARA RETORNAR EL USUARIO
  getSesion(): any{
    return localStorage.getItem("sesion");
  }

  getCustomer(): any {
    const sesion = localStorage.getItem('sesion');
    return Number(JSON.parse(sesion!).customer);
  }
  
  getService(): any {
    const service = localStorage.getItem('service');
    return Number(service);
  }
  

  // PARA RETORNAR EL USUARIO
  getTime(): any{
    return localStorage.getItem(this.APP_USER_TIME);
  }

  // PARA CERRAR LA SESION DEL USUARIO
  closeSesion(){ // REMOVEMOS TODOS LAS VARIABLES
    localStorage.removeItem("sesion");
    localStorage.removeItem("principalBanner");
    localStorage.removeItem("logoImage");
    localStorage.removeItem("plan");
    localStorage.removeItem("service");
    localStorage.removeItem("partner");
  }

}
