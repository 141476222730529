import { Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; // Asegúrate de tener esta importación
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { ThemeService } from '../../theme.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-content-dashboard',
  templateUrl: './content-dashboard.component.html',
  styleUrls: ['./content-dashboard.component.scss']
})
export class ContentDashboardComponent {
  nombreEmpresa: string = 'Billcentrix';
  nombreUsuario: string = ''; // Nombre de usuario
  correoUsuario: string = '';
  telefonoUsuario : string = '';
  imagenAleatoria: string; //Imagen Aleatoria
  constructor(public themeService: ThemeService, private auth : AuthService,private web : WebService,){
  this.web.get(this.web.HOST + '/ecommerce/customer/' + this.auth.getCustomer()).subscribe(response =>{
      this.nombreUsuario = response.result.name_customer + " " + response.result.lastName_customer;
      this.correoUsuario = response.result.email_customer;
      this.telefonoUsuario = response.result.phoneNumber_customer;
  }, err =>{
    //console.log(err);
  })
 // Selecciona una imagen de perfil aleatoria de una lista de imágenes
 const perfilImagenes: string[] = [
  '../assets/images/profile/men-01.png',
  '../assets/images/profile/men-02.png',
  '../assets/images/profile/men-03.png',
  '../assets/images/profile/men-04.png',
  '../assets/images/profile/girl-02.png',
  '../assets/images/profile/girl-03.png',
  '../assets/images/profile/girl-04.png',
  '../assets/images/profile/girl-05.png',
  // Agrega más rutas de imágenes según sea necesario
];
this.imagenAleatoria = perfilImagenes[Math.floor(Math.random() * perfilImagenes.length)];
    
  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
}
const routes: Routes = [
  {
    path: 'dashboard',
    component: ContentDashboardComponent,
    children: [
      { path: 'credit-card', component: CreditCardComponent }, // Ruta de Tarjeta de Crédito
      // Otras rutas del dashboard aquí
    ],
  },
]; 