import { Component,OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagesService } from '../services/image/images.service';
import { LoaderService } from '../services/loader.service';
import { NotificationService } from '../services/notification.service';
import { WebService } from '../services/web.service';
import { ThemeService } from '../theme.service';

@Component({
  selector: 'app-register',
  templateUrl:'./register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  themeColors: any;

  form: any ;
  errorNumber = true;
  numberRequired = false;
  config : any;
  partner : any;
  service: any;
  showButtons : Boolean = true;
  isPreview : Boolean = false;
  param : any;

 constructor(private router: Router,public themeService: ThemeService,private activeRoute : ActivatedRoute,private imageService: ImagesService, private web : WebService, private notidication : NotificationService, private loader : LoaderService) {
  this.form = new FormGroup({
    name_customer: new FormControl('', Validators.required),
    lastName_customer: new FormControl('', Validators.required),
    identifier_customer: new FormControl('', Validators.required),
    email_customer: new FormControl('', [Validators.required,Validators.email]),
    phoneNumber_customer:new FormControl('',Validators.required)
  });
  this.activeRoute.params.subscribe( params =>{
    this.isPreview = params["id"] != undefined ? true : false;
      if(this.isPreview){
       this.param = params["id"]
      }
  }, err=>{
    this.notidication.showError(err)
  });

 }
  
  goToMainPage() {
    this.router.navigate(['/basic']);
  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

  ngOnInit(): void {

    this.loader.open();

    this.activeRoute.params.subscribe( params =>{

      let param = params['id'] != undefined ? params['id'] : -1; 
      // this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "dev.onboarding.practic.billcentric.com" + "/"  + param).subscribe( response =>{ 
      // this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "dev.onboarding.contempo.billcentric.com" + "/"  + 178).subscribe( response =>{ 
      // this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + "shoes.billcentric.com" + "/"  + param).subscribe( response =>{ 
       this.web.get(this.web.HOST + "/onboardingConfig/bydomain/" + window.location.host + "/"  + param ).subscribe( response =>{
            this.loader.close();
            this.partner = response.result.partner.id;
            this.service = response.result.service.id;
            this.config = response.result.id;
            // if(param === -1 && (response.result.customerHasPlan != null && response.result.customerHasPlan.status_customerPlan == 1) ){
            //   this.partner = response.result.partner.id;
            //   this.service = response.result.service.id;
            //   this.config = response.result.id;
            // }else{
            //   this.showButtons = false;
            //   return this.notidication.showError("Por favor, culmina el pago de tu portal para que se pueda desplegar correctamente...");
            // }
            
           
          }, err =>{
            this.loader.close();
            this.notidication.showError(err);
          });



    }, err =>{
      this.loader.close();
      this.notidication.showError(err);
    });

  }


  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onCountryChange(obj:any){
    ////console.log(obj);
  }

  telInputObject(obj:any){
    ////console.log(obj);
  }

  getNumber(obj:any){
    this.form.controls["phoneNumber_customer"].setValue(obj);
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  signup(){



    if(!this.form.valid){
      return this.notidication.showError("Por favor, complete el formulario");
    }

    this.form.value.config = this.config;
    this.form.value.code_customer = this.form.value.email_customer;
    this.form.value.status_customer = 1;
    this.form.value.service = this.service;
    this.form.value.partner = { id : Number(this.partner)};

    // //console.log(this.form.value);

    this.loader.open();

    this.web.post(this.form.value, this.web.HOST + "/onboarding/user").subscribe(response =>{
      this.notidication.showSuccess("Cuenta creada correctamente, se ha enviado un correo con sus credenciales, por favor inicie sesión");
      this.loader.close();
     this.goToLogin();

    }, err =>{
      this.notidication.showError(err.message);
      //console.log(err);
      this.loader.close();
    })

    
  }

  goToLogin(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/login"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/login"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
    }
  }


}