import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {WebService} from "../services/web.service";

@Injectable()
export class Logging implements HttpInterceptor {

    constructor(private http: HttpClient, private webService : WebService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        const serviceUrl = req.urlWithParams; // URL del servicio que está siendo invocado
        // Omitir las solicitudes al endpoint /log para evitar el loop infinito
        // OMITIMOS los servicios GET
        if (serviceUrl.includes('/log') || req.method.includes("GET")) {
            // console.log("SALIMOS SIN LOG")
            return next.handle(req); // No hacer nada y continuar con la solicitud
        }

        // console.log("REQ: ", req)
        const requestBody = req.body; // Cuerpo del request (si tiene)
        const userId = req.headers.get("user"); // Obtener el ID del usuario (de algún servicio de autenticación si es necesario)

        // Crear el payload para enviar al backend
        const logData = {
            userId: userId,
            serviceUrl: serviceUrl,
            requestBody: requestBody,
            method: req.method,
            type : "TEMPLATE-CONTEMPO"
        };

        // console.log("ESTO ENVIO: ", JSON.stringify(logData, null, 2));

        // Enviar la información al servicio de logging en el backend
        this.http.post(this.webService.HOST + '/log', logData).subscribe({
            // next: () => console.log('Log guardado exitosamente.'),
            // error: err => console.error('Error guardando log:', err)
        });

        // Continuar con la solicitud original
        return next.handle(req);

        // // Continuar con la solicitud original y capturar la respuesta
        // return next.handle(req).pipe(
        //     tap(
        //         event => {
        //             if (event instanceof HttpResponse) {
        //
        //                 // Preparar los datos para registrar en el backend
        //                 const logData = {
        //                     userId: userId,
        //                     serviceUrl: serviceUrl,
        //                     requestBody: requestBody,
        //                     responseStatus: event.status,
        //                     type : "ONBOARDING"
        //                 };
        //                 console.log("ENVIO ESTO: ", logData)
        //                 // Llamar al servicio de logging en el backend
        //                 this.http.post(this.webService.HOST + '/log', logData).subscribe({
        //                     // next: () => console.log('Log guardado exitosamente.'),
        //                     // error: err => console.error('Error guardando log:', err)
        //                 });
        //             }
        //         },
        //         error => {
        //             // Si hay un error en la respuesta, también puedes capturarlo y registrarlo
        //             const logErrorData = {
        //                 userId: userId,
        //                 serviceUrl: serviceUrl,
        //                 responseStatus: error.status,
        //                 error: error.message,
        //                 type : "ONBOARDING"
        //             };
        //             this.http.post(this.webService.HOST + '/log', logErrorData).subscribe({
        //                 // next: () => console.log('Log guardado exitosamente.'),
        //                 // error: err => console.error('Error guardando log:', err)
        //             });
        //         }
        //     )
        // );
    }
}
