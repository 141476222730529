import { Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeService } from '../../theme.service';
import { AddCardFormComponent } from '../add-card-form/add-card-form.component'
import { MatDialog } from '@angular/material/dialog';
import { AddAddressComponent } from '../add-address/add-address.component';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})

export class CreditCardComponent {

  tdc: any = [];
  tarjetas: any = [];
  items: Array<any> = [];
  tarjetasActivasCorer: Array<any> = [];
  tarjetasRegistradasCustomer: Array<any> = [];
  
  themeColors: any; 
  isAddCardFormVisible = false; 
  isDialogOpen = false;

  currentPage = 1; // Página inicial
  itemsPerPage = 5; // Número de direcciones por página
  public totalPages = 0 ;

  constructor(public themeService: ThemeService, private dialog: MatDialog, private auth: AuthService, private web: WebService, private notification: NotificationService, private loader: LoaderService) {
    this.loader.open();
    this.tarjetasActivasCorer = [];
    // ESTO ES ESENCIAL PARA LA PAGINACION
    let httpParams = this.web.buildRequestParams(null,"m",
        {pageIndex: +this.currentPage - 1 , pageSize: this.itemsPerPage})
    this.web.get(this.web.HOST + "/customer/list/" + this.auth.getCustomer() + "/creditcard", httpParams).subscribe(response => {
      this.totalPages = response.pager.pages;
      this.tarjetasActivasCorer = response.result;
      this.web.get(this.web.HOST + '/creditcard/customer/' + this.auth.getCustomer()).subscribe(response => {
        if (response['result'].length > 0 ) {
          this.tarjetasRegistradasCustomer = response['result'];
          const tarjetasActivasCorerRegistradas = this.tarjetasRegistradasCustomer.filter(tarjetaCustomer =>
              this.tarjetasActivasCorer.some(tarjetaCorer =>
                  tarjetaCustomer.corerId === tarjetaCorer.id
              )
          );

          tarjetasActivasCorerRegistradas.forEach(tarjeta => {
            this.tdc.push({ id: tarjeta.corerId, type: tarjeta.creditCardProvider.description, number: tarjeta.mask_card, status: 1, token : tarjeta.token_creditCardToken});
          });
          this.loader.close();
          console.log("Este es el arreglo completo: ", tarjetasActivasCorerRegistradas )
        }else {
          this.loader.close();
        }
      })
    })
  }

  find(){
    this.loader.open();
    this.tarjetasActivasCorer = [];
    // ESTO ES ESENCIAL PARA LA PAGINACION
    let httpParams = this.web.buildRequestParams(null,"m",
        {pageIndex: +this.currentPage - 1 , pageSize: this.itemsPerPage})
    this.web.get(this.web.HOST + "/customer/list/" + this.auth.getCustomer() + "/creditcard", httpParams).subscribe(response => {
      this.totalPages = response.pager.pages;
      this.tarjetasActivasCorer = response.result;
      this.web.get(this.web.HOST + '/creditcard/customer/' + this.auth.getCustomer()).subscribe(response => {
        if (response['result'].length > 0 ) {
          this.tarjetasRegistradasCustomer = response['result'];
          const tarjetasActivasCorerRegistradas = this.tarjetasRegistradasCustomer.filter(tarjetaCustomer =>
              this.tarjetasActivasCorer.some(tarjetaCorer =>
                  tarjetaCustomer.corerId === tarjetaCorer.id
              )
          );

          tarjetasActivasCorerRegistradas.forEach(tarjeta => {
            this.tdc.push({ id: tarjeta.corerId, type: tarjeta.creditCardProvider.description, number: tarjeta.mask_card, status: 1, token : tarjeta.token_creditCardToken});
          });
          this.loader.close();
          console.log("Este es el arreglo completo: ", tarjetasActivasCorerRegistradas )
        }else {
          this.loader.close();
        }
      })
    })
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.find();
    }
  }


  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.find();
    }
  }


  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

  toggleAddCardForm() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(AddCardFormComponent, {
        width: '800px', height: '500px'
      });
      dialogRef.afterClosed().subscribe(() => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
        window.location.reload();
      });
    }
  }

  changeStatus(tdc: any) {
    this.loader.open();

    if (!tdc.id) {
      this.loader.close();
      return this.notification.showInfo("No se puede eliminar la tarjeta");
    }

    const action = tdc.status.status === 1 ? 'delete' : 'activate';
    const url = `${this.web.HOST}/customer/${this.auth.getCustomer()}/creditcard/${tdc.id}/${action}`;
    const json = { token: tdc.status.token };

    this.web.post(json, url).subscribe(
        () => {
          this.loader.close();
          this.notification.showSuccess("Actualizada correctamente");
          window.location.reload();
        },
        () => {
          this.loader.close();
          this.notification.showError("No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo");
        }
    );
  }
}
