import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { WebService } from 'src/app/services/web.service';

import { ThemeService } from '../../../theme.service';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['../direcciones.component.scss']
})
export class AddressComponent implements OnInit {

  @Output() manageStatus = new EventEmitter<{status : Number, id : Number}>();

  @Input() direction: {
    id: Number, 
    address: String, 
    status: Number, 
    phone: String, 
    province: Number, 
    corregimiento: Number,
    provinceName?: String, 
    corregimientoName?: String 
  } | any = null;
  provinces: any[] = [];
  @Output() editDirection = new EventEmitter<{id:Number, address: String; status: Number; phone: String; province : Number; corregimiento : Number;corregimientoName: String;provinceName: String }>();

  

  constructor(public themeService: ThemeService,private webService: WebService) { }

  ngOnInit(): void {
    this.loadProvinces();
  }
  loadProvinces() {
    this.webService.get(this.webService.HOST + "/province").subscribe(provinces => {
      this.provinces = provinces.result;

      // Una vez cargadas las provincias, buscar los nombres
      if (this.direction) {
        this.findNames();
      }
    });
  }

  findNames() {
    const selectedProvince = this.provinces.find(p => p.id === this.direction.province);
    if (selectedProvince) {
      this.direction.provinceName = selectedProvince.name;

      const selectedCorregimiento = selectedProvince.corregimientos.find(c => c.id === this.direction.corregimiento);
      if (selectedCorregimiento) {
        this.direction.corregimientoName = selectedCorregimiento.name;
      }
    }
  }


  changeStatus(newStatus : Number){
    this.manageStatus.emit({ status : newStatus, id: this.direction.id })
  }

  sendToEdit(item : any){
    this.editDirection.emit(item);
  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

}
