import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SuscriptionsService {

  public suscriptions = new EventEmitter<any[]>();
  public suscriptionUpdated = new EventEmitter<boolean>();

  constructor() { }

}
