import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { WebService } from 'src/app/services/web.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoaderService } from 'src/app/services/loader.service';
import { forkJoin } from 'rxjs';

export interface Tarjeta {
  nombre: string;
  tarjetas: number;
  acciones: string;
}

@Component({
  selector: 'app-mistarjetas',
  templateUrl: './mistarjetas.component.html',
  styleUrls: ['./mistarjetas.component.scss']
})
export class MistarjetasComponent implements OnInit {
  empty = false;
  tarjetas: any[] = [];
  items: Array<any> = [];
  displayedColumns: string[] = ['nameHolderCreditCard', 'expMonthCreditCard', 'actions'];
  dataSource = new MatTableDataSource<Tarjeta>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MistarjetasComponent>,
    private auth: AuthService,
    private web: WebService,
    private notification: NotificationService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    this.loader.open();
    this.loadCreditCards();
  }

  loadCreditCards() {
    const customerId = this.auth.getCustomer();
    this.web.get(`${this.web.HOST}/customer/${customerId}/creditcard`).subscribe(
      (params) => {
        if (params['result']) {
          this.tarjetas = params['result'];
          this.processCreditCards();
        } else {
          this.loader.close();
        }
      },
      (err) => {
        this.notification.showError(err);
        this.loader.close();
      }
    );
  }

  processCreditCards() {
    const requests = this.tarjetas.map((tarjeta) => {
      const tdc = tarjeta.creditCard;
      tdc.expMonth = this.getMonthName(tdc.expMonthCreditCard);
      tdc.maskCreditCard = tdc.maskCreditCard.substr(tdc.maskCreditCard.length - 8);
      tdc.expYearCreditCard = "20" + tdc.expYearCreditCard;
      tdc.expMonthCreditCard = tarjeta.providerCreditCard.description;

      return this.web.get(`${this.web.HOST}/customer/${this.auth.getCustomer()}/customerHasCreditCard/${tdc.id}`);
    });

    forkJoin(requests).subscribe(
      (responses: any[]) => {
        responses.forEach((response, index) => {
          const tdc = this.tarjetas[index].creditCard;
          tdc.estatus = response['result']['statusCreditCard'];
          tdc.token = response['result']['tokenCreditCard'];

          // Filtrar por estatus 1
          if (tdc.estatus === 1) {
            this.items.push(tdc);
          }
        });

        this.dataSource.data = this.items;
        this.dataSource.paginator = this.paginator;
        this.loader.close();
      },
      (err) => {
        this.notification.showError(err);
        this.loader.close();
      }
    );
  }

  getMonthName(monthNumber: number): string {
    const months = [
      "ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
      "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"
    ];
    return months[monthNumber - 1] || '';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  select(row: any) {
    this.dialogRef.close(row);
  }
}
