import { HttpParams } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagesService } from '../services/image/images.service';
import { LoaderService } from '../services/loader.service';
import { LoginService } from '../services/login/login.service';
import { NotificationService } from '../services/notification.service';
import { WebService } from '../services/web.service';
import { ThemeService } from '../theme.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  themeColors: any; 

  form : any;
  fromCheckout : Boolean = false;
  isLoginFromCheckout : Boolean = false;
  isPreview : Boolean = false;
  param : any;

  constructor(private router: Router,public themeService: ThemeService,public loginService : LoginService,private activeRoute : ActivatedRoute, private imageService : ImagesService,private web : WebService, private notification : NotificationService, private loader : LoaderService) {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email]),
      password: new FormControl('', Validators.required)
    });


    if(loginService.getFromCheckout() != null){
      this.form.controls["username"].setValue(loginService.getFromCheckout());
      this.isLoginFromCheckout = true;
      if(loginService.getPassFromCheckout() != null){
        this.form.controls["password"].setValue(loginService.getPassFromCheckout());
        this.signin();
      }
      loginService.removeFromCheckout();
    }

    this.activeRoute.params.subscribe( params =>{
      // ESTO ES PARA VALIDAR SI ES UNA VISTA PREVIA O SE ESTA CARGANDO UN PORTAL YA CREADO
      this.isPreview = params["id"] != undefined ? true : false;
      if(this.isPreview){
       this.param = params["id"]
      }
      this.activeRoute.queryParams.subscribe( queryParams =>{
        if(queryParams['checkout']){
          this.fromCheckout = true;
        }
      }, err =>{
        this.notification.showError(err);
      });
   });

  }

  goToMainPage() {
    this.router.navigate(['/basic']);
  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }



  onRegisterClick(){
    
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/register"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/register"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
    }

  }

  onforgotClick(){

    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/forgot"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/forgot"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
    }

  }

  signin(){
    
    if(!this.form.valid){
      return this.notification.showError("Por favor complete el formulario...");
    }

    this.loader.open();

    const signinData = this.form.value;

    let params = new HttpParams() ;
   
		params = params.set('grant_type', 'password');
		params = params.set('username', signinData.username);
		params = params.set('password', signinData.password);
		params = params.set('client_id', 'web_site');
    params = params.set('partner_id', Number(localStorage.getItem("partner")));
    //SOLICITAMOS TOKEN
    this.web.auth(params,this.web.HOST + "/oauth/token").subscribe( res =>{

      this.loader.close();
      localStorage.setItem('sesion',JSON.stringify(res));

        if(!this.isLoginFromCheckout){ // DECIDIMOS DONDE ENVIAR AL CLIENTE, EN CASO DE QUE VENGA DESDE EL CHECKOUT ENTONCES LO DEVOLVEMOS
        this.notification.showSuccess("Ha iniciado sesión correctamente");
        // this.router.navigateByUrl("/dashboard/dashboard-content");
        if(this.isPreview){
          this.router.navigate(["/preview/" + this.param + "/dashboard/dashboard-content"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
        }else{
          this.router.navigate(["/dashboard/dashboard-content"], {relativeTo : this.activeRoute, queryParamsHandling : "preserve"});
        }
      }else{
        // this.dialogRef.close();
      }
      

    }, err =>{
      //console.log(err);
      this.loader.close();
      if(err.error == "invalid_authUser - Email NO existe"){
        this.notification.showError("Disculpe, este correo no posee una cuenta asociada")
      }else{
        this.notification.showError(err.error);
      }
      
    });
  
  }



}
 