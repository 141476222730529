import { Component, ViewChild, ViewContainerRef, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-container',
  template: `<div #dialogContent></div>`, // Usa un div como contenedor del contenido
})
export class AppDialogContainerComponent implements AfterViewInit {
  @ViewChild('dialogContent', { static: true }) dialogContent!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<AppDialogContainerComponent>,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    // El contenido dinámico se agregará aquí
  }
}
