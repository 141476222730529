import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent {

  provinces: any[]= [] ;
  form: any ;
  corregimientos: any[]= [] ;
  partnerCorregimientos : any[]= [] ;


  constructor(public dialogRef: MatDialogRef<AddAddressComponent>,
    private auth : AuthService,private webService : WebService, private notification : NotificationService, private loader : LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any){
      this.loader.open();  
      this.form = new FormGroup({
        adress: new FormControl('', Validators.required),
        province : new FormControl('', Validators.required),
        corregimiento : new FormControl('', Validators.required)
      });
      
      this.webService.get(this.webService.HOST + "/partner/" + localStorage.getItem("partner")).subscribe(response =>{
        this.loader.close()
        this.provinces = response.result.provinces;
        this.partnerCorregimientos = response.result.partnerCorregimientos;
        this.provinces = this.provinces.sort((a,b) => a.name > b.name ? 1 : -1);// ORDENAMOS ALFABETICAMENTE
  
      }, err =>{
            this.loader.close()
            this.notification.showError(err);
      });

    }
  closeDialog(): void {
    this.dialogRef.close();
  }


  onProvinceChange(){

    this.corregimientos = [];
    const selectedProvinceId = Number(this.form.value.province);
    const uniqueCorregimientos = new Map();
    this.partnerCorregimientos
      .filter(c => c.province.id === Number(selectedProvinceId))
      .forEach(c => {
        if (!uniqueCorregimientos.has(c.corregimiento.id)) {
          uniqueCorregimientos.set(c.corregimiento.id, c.corregimiento);
        }
      });
    this.corregimientos = Array.from(uniqueCorregimientos.values())
      .sort((a, b) => a.name.localeCompare(b.name));

  }


  create(){

    if(!this.form.valid){
      return this.notification.showError("Por favor complete el formulario")
    }

    this.loader.open();
    this.form.value.customer = { id: this.auth.getCustomer() } ;
    this.form.value.status = 1;
    this.form.value.province = {id: Number(this.form.value.province) };
    this.form.value.corregimiento = {id: Number(this.form.value.corregimiento) }; // ESTO ESTA FIJO
    this.webService.post(this.form.value, this.webService.HOST + "/adress").subscribe(res =>{
        this.loader.close();
        this.notification.showSuccess("Creada correctamente");
        this.closeDialog();
    }, err=>{
      this.loader.close();
      this.notification.showError(err);
    });
    
  }



}

