<button class="close" mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>

   
<div class="form_dialog">
    <h1>Actualizar Dirección</h1>

    <form >
        <div class="form-group">
            <label for="provincia">Provincia</label>
            <select id="provincia" formControlName="provincia" required>
                <option value="" disabled>Seleccione una Provincia</option>
              <option value="Panamá" selected>Panamá</option>
              <option value="Chiriquí">Chiriquí</option>
              <option value="Coclé">Coclé</option>
            </select>
          </div>
          <div class="form-group">
            <label for="provincia">Corregimiento</label>
            <select id="provincia" formControlName="Corregimiento" required>
                <option value="" disabled>Seleccione un Corregimiento</option>
              <option value="Panamá" selected>Panamá</option>
              <option value="Chiriquí">Chiriquí</option>
              <option value="Coclé">Coclé</option>
            </select>
          </div>
      <div class="form-group">
        <input type="text" id="direcciones" formControlName="nombreTitular" placeholder="Añadir direccion" required>
      </div>
    
      <button type="submit">Crear</button>
    </form>
  </div>
  