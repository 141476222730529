import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-creditcard-info',
  templateUrl: './creditcard-info.component.html',
  styleUrls: ['../credit-card.component.scss']
})
export class CreditcardInfoComponent {

  @Output() manageStatus = new EventEmitter<{status : Number, id : Number}>();


  @Input() tarjeta : { id:Number ; type: String; number: String; status : String } | any ;

  constructor() { }

  ngOnInit(): void {
  }

  changeStatus(newStatus : Number){
    this.manageStatus.emit({ status : newStatus, id: this.tarjeta.id });
  }


}
